/**
 * Frontend styles via Bootstrap Framework.
 */
$primary: #f90;

@import 'node_modules/bootstrap/scss/bootstrap';

/**
 * Icons via Font Awesome.
 */
$fa-font-path: '/lib';
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';

/*
 * Classes for column-count
 */
@each $columnCount in [2, 3, 4] {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            .cols#{$infix}-#{$columnCount} {
                column-count: #{$columnCount};
            }
        }
    }
}

/**
 * Margin to align buttons within rows
 */
.btn-m {
    margin-top: 2rem !important;
}

/**
 * same as .form-control:disabled
 */
.form-control.disabled {
    background-color: var(--bs-secondary-bg);
    opacity: 1;
}

$image-sizes: 40px, 80px, 120px, 160px, 200px;

@each $size in $image-sizes {
  .img-#{$size} {
    max-width: $size;
    max-height: $size;
  }
}

.navbar-margin {
  margin-top: 4rem !important
}

.file-manager-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.file-manager-actions > * {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.file-manager-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.file-item {
  position: relative;
  z-index: 1;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background: rgb(255, 255, 255);
  border: 1px solid #eee;
  cursor: pointer;
}

.file-item * {
  flex-shrink: 0;
  text-decoration: none;
}

.file-item-checkbox {
  margin: 0 !important;
}

.file-item-select-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.file-item-img {
  background-color: transparent;
  background-position: center center;
  background-size: cover;
}

.file-item-name {
  display: block;
  overflow: hidden;
  color: #000;
}

.file-manager-col-view .file-item {
  margin: 0 0.25rem 0.25rem 0;
  padding: 1.25rem 0 1rem 0;
  width: 100%;
  text-align: center;
}

.file-manager-col-view .file-item-img,
.file-manager-col-view .file-item-icon {
  display: block;
  margin: 0 auto 0.75rem auto;
  max-width: 100%;
  max-height: 5.5rem;
  font-size: 2.5rem;
  line-height: 5.5rem;
}

.file-manager-col-view .file-item-level-up {
  font-size: 1.5rem;
}

.file-manager-col-view .file-item-checkbox,
.file-manager-col-view .file-item-actions {
  position: absolute;
  top: 6px;
}

.file-manager-col-view .file-item-checkbox {
  left: 6px;
}

.file-manager-col-view .file-item-actions {
  right: 6px;
}

.file-manager-col-view .file-item-name {
  width: 100%;
}

.file-manager-col-view .file-manager-row-header,
.file-manager-col-view .file-item-changed {
  display: none;
}

.file-manager-row-view .file-manager-row-header,
.file-manager-row-view .file-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 0.125rem 0;
  padding: 0.25rem 3rem 0.25rem 2.25em;
  width: 100%;
}

.file-manager-row-view .file-item-img,
.file-manager-row-view .file-item-icon {
  display: block;
  margin: 0 1rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  font-size: 1.25rem;
  line-height: 2rem;
}

.file-manager-row-view .file-item-level-up {
  font-size: 1rem;
}

.file-manager-row-view .file-item-checkbox,
.file-manager-row-view .file-item-actions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.file-manager-row-view .file-item-checkbox {
  left: 10px;
}

.file-manager-row-view .file-item-actions > * {
  z-index: 1000;
}

.file-manager-row-view .file-item-actions .dropdown-menu > * {
  z-index: 1000 !important;
}

.file-manager-row-view .file-item-changed {
  display: none;
  margin-left: auto;
  width: 10rem;
}

.file-manager-row-view .file-item-name {
  width: calc(100% - 4rem);
}

.file-manager-row-view .file-manager-row-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  font-weight: bold;
}

.file-manager-row-view .file-manager-row-header .file-item-name {
  margin-left: 4rem;
}
